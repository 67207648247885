<template>
  <Sidebar
    :is-open="isOpen"
    :with-backdrop="isMobile && isOpen"
    @on-backdrop-click="$emit('onBackdropClick')"
  >
    <template #content>
      <div class="px-3">
        <img
          :src="logo"
          alt="Organization logo"
          class="ml-4 mt-8 mb-4 w-29 h-29 object-contain"
        />

        <SidebarOption
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          :label="option.label"
          :icon="option.icon"
          :to="option.to"
          :sub-options="option.subOptions"
        />
      </div>
    </template>
    <template #sidebar-bottom>
      <SidebarBottom />
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { Sidebar } from '~/layouts/_components/sidebar';
import { useOrganizationStore } from '~/store/organization';
import ContentSocialsSvg from '~/assets/icons/content-socials.svg?component';
import DashboardSvg from '~/assets/icons/dashboard.svg?component';
import GalleryAddSvg from '~/assets/icons/gallery-add.svg?component';
import PrinterSvg from '~/assets/icons/printer.svg?component';
import ExfluentialLogoSrc from '~/assets/images/exfluential-logo.svg?url';
import {
  computed,
  useIsMobile,
  useOrganizationDomain,
  useRuntimeConfig,
} from '#imports';
import { SidebarBottom } from './_components/sidebar-bottom';
import SidebarOption from '~/layouts/_components/left-sidebar/_components/sidebar-option/sidebar-option.vue';
import PromoteSvg from '~/assets/icons/promote.svg?component';
import { useAccountStore } from '~/store/account';

type Props = {
  isOpen: boolean;
};

defineProps<Props>();
const emit = defineEmits<{
  toggle: [];
  onBackdropClick: [];
}>();

const config = useRuntimeConfig();

const { t } = useI18n();
const organizationDomain = useOrganizationDomain();
const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);
const { currentAccount } = storeToRefs(useAccountStore());
const isMobile = useIsMobile();

const logo = computed<string>(() => {
  if (!organizationDomain) {
    return ExfluentialLogoSrc;
  }

  if (organization.value) {
    return organization.value.logoUrl;
  }

  return ExfluentialLogoSrc;
});
const options = computed(() => [
  { icon: DashboardSvg, label: t('layout.dashboard'), to: '/' },
  { icon: ContentSocialsSvg, label: t('layout.my-posts'), to: '/my-posts' },
  ...(organization.value?.isPrintEnabled
    ? [{ icon: PrinterSvg, label: t('layout.print'), to: '/print' }]
    : []),
  ...(organizationDomain && organization.value?.isContentCustomizationEnabled
    ? [
        {
          icon: GalleryAddSvg,
          label: t('layout.customize'),
          subOptions: [
            {
              icon: ContentSocialsSvg,
              label: t('layout.view-customizable-content'),
              to: '/customizable-content',
            },
            {
              icon: ContentSocialsSvg,
              label: t('layout.my-customized-content'),
              to: '/my-customized-content',
            },
          ],
        },
      ]
    : []),
  ...(organization.value?.isCoOp &&
  currentAccount.value?.currentlySelectedBusiness
    ? config.public.environment === 'production'
      ? [
          {
            icon: PromoteSvg,
            label: t('layout.budgets'),
            to: '/budgets',
          },
        ]
      : [
          {
            icon: PromoteSvg,
            label: t('layout.promote'),
            subOptions: [
              {
                label: t('layout.promote'),
                to: '/promote',
              },
              {
                label: t('layout.budgets'),
                to: '/budgets',
              },
            ],
          },
        ]
    : []),
]);
</script>
